// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  isSupport: false,
  apiBaseUrl: "https://iselltestapi.technoapps.net/",
  googleAPIUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  googleAPIKey: "AIzaSyCHRCMw0jsO41118g_TK52mbBB7Ww2kPeE",
  fileURL: "https://iselltestapi.technoapps.net/api/Images/",
  userHubUrl: "https://iselltestapi.technoapps.net/hubs",
  tokenKeyName: "Isaletoken",
  attendanceTimeIn: "16:15",
  attendanceTimeOut: "23:00",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
