import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfigService } from './app.AppConfigService';
import { HttpConfigInterceptor } from './interceptor/HttpConfigInterceptor';

export function initConfig(appConfigService: AppConfigService) {
    return () => appConfigService.loadConfig();
  }
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        FormsModule
    ],
    declarations: [AppComponent],
    providers: [AuthGuard,
      AppConfigService,
        {
        provide: APP_INITIALIZER,
        useFactory: initConfig,
        deps: [ AppConfigService ],
        multi: true}
        , { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
      ],
    bootstrap: [AppComponent]
})
export class AppModule { }
