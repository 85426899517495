import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./shared";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "prefix" },
  {
    path: "",
    loadChildren: () =>
      import("./layout/layout.module").then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "Registration-Request-Inquiry",
    loadChildren: () =>
      import(
        "./registration-request-inquiry/registration-request-inquiry.module"
      ).then((m) => m.RegistrationRequestInquiryModule),
  },
  {
    path: "forgotPassword",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "resetPassword",
    loadChildren: () =>
      import("./reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./server-error/server-error.module").then(
        (m) => m.ServerErrorModule
      ),
  },
  {
    path: "access-denied",
    loadChildren: () =>
      import("./access-denied/access-denied.module").then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./not-found/not-found.module").then((m) => m.NotFoundModule),
  },
  {
    path: "CurrentSuppliers",
    loadChildren: () =>
      import("./current-suppliers/current-suppliers.module").then(
        (m) => m.currentSuppliersModule
      ),
  },
  {
    path: "Print",
    loadChildren: () =>
      import("./Print/print.module").then((m) => m.PrintModule),
  },
  {
    path: "UsagePolicy",
    loadChildren: () =>
      import("./UsagePolicy/UsagePolicy.module").then(
        (m) => m.UsagePolicyModule
      ),
  },
  {
    path: "UserRegistration",
    loadChildren: () =>
      import("./UserRegistration/UserRegistration.module").then(
        (m) => m.UserRegistrationModule
      ),
  },
  { path: "**", redirectTo: "not-found" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
