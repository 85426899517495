import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppConfigService {
    config: any;
    constructor(private _http: HttpClient) { }

    public loadConfig() {
        return this._http.get('./assets/config/config.json')
            .toPromise()
            .then((config: any) => {
                this.config = config;
                for (const key in config) {
                    environment[key] = config[key];
                }
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    public getConfig() {
        return this.config;
    }
}
