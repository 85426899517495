import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../DataServices/Authentication.service";
import { AuthModel } from "../../Models/AuthModel";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate() {
    let authModel: AuthModel;
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["login"]);
      return false;
    }

    return true;
  }
}
