import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthModel } from "../Models/AuthModel";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiBaseUrl) && request.url != environment.apiBaseUrl + 'api/FileUpload')
     {
      let tokenName = environment.tokenKeyName ?? "token";
      let token: string = localStorage.getItem(tokenName);
      let AuthData: AuthModel = JSON.parse(
        localStorage.getItem(tokenName)
      ) as AuthModel;
      if (AuthData != null) token = AuthData.token;
      let requestHeader: HttpHeaders;
      requestHeader = new HttpHeaders({
        Authorization: `bearer ${token}`,
        Lang: "ar",
      });
      if (requestHeader) {
        request = request.clone({ headers: requestHeader });
      }
      if (!request.headers.has("Content-Type")) {
        request = request.clone({
          headers: request.headers.set("Content-Type", "application/json"),
        });
      }
      request = request.clone({
        headers: request.headers.set("Accept", "application/json"),
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}
