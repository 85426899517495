import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amPmFormat'
})
export class AmPmFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const timeParts = value.split(':');
    let hours = parseInt(timeParts[0], 10);
    const minutes = timeParts[1];

    const amPm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${amPm}`;
  }
}
